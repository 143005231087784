import React from 'react';

import { useLocation } from '@reach/router';
import { graphql } from 'gatsby';

import { HeadingContextProvider } from '@af/design-system-docs-ui';

import PageWithLocalNav from '../../__DO_NOT_ADD_TO_THIS_FOLDER__/gatsby-theme-brisk/templates/page-with-local-nav';
import titleify from '../../__DO_NOT_ADD_TO_THIS_FOLDER__/gatsby-theme-brisk/utilities/titleify';

import PageContent from './content';
import getHeroSectionText from './utils/getHeroSectionText';
import { getCurrentTab } from './utils/use-tabs';

const getPageInfo = (pageType: 'page' | 'codePage' | 'tabbedPage', data: any, currentTab: any) => {
	switch (pageType) {
		case 'page':
			return getHeroSectionText(data.mdx.nodes[0], data.workspaceInfo);
		case 'codePage':
			return {
				title: data.workspaceInfo.title,
				description: data.workspaceInfo.description,
				status: data.workspaceInfo.status,
			};
		case 'tabbedPage':
			if (currentTab.name === 'code') {
				return {
					title: data.workspaceInfo.title,
					description: data.workspaceInfo.description,
					status: data.workspaceInfo.status,
				};
			}
			return getHeroSectionText(currentTab.mdx, data.workspaceInfo);
	}
};

type PageContext = {
	hasCodeDocs: boolean;
	hasTabs: boolean;
	hasMdx: boolean;
};

const getPageType = ({ hasCodeDocs, hasTabs, hasMdx }: PageContext) => {
	if (!hasMdx && hasCodeDocs) {
		// if there's no mdx, render just code docs
		return 'codePage';
	} else if (hasMdx && !hasTabs && !hasCodeDocs) {
		// if there's one mdx page and no code docs
		return 'page';
	} else {
		return 'tabbedPage';
	}
};

interface HomePageProps {
	data: any;
	pageContext: PageContext;
}

export default ({ data, pageContext }: HomePageProps) => {
	const pageType = getPageType(pageContext);
	let currentTab: { name: string; mdx: any } | undefined;

	if (pageType === 'tabbedPage') {
		const { pathname } = useLocation();
		currentTab = getCurrentTab(pathname, data.mdx.nodes);
	}
	const { title, description, status } = getPageInfo(pageType, data, currentTab);

	return (
		<HeadingContextProvider page={currentTab?.name}>
			<PageWithLocalNav
				title={title}
				description={description}
				status={status}
				tabName={currentTab ? titleify(currentTab.name) : undefined}
				guidelineCategory="Components"
			>
				<PageContent pageType={pageType} data={data} pageContext={pageContext} />
			</PageWithLocalNav>
		</HeadingContextProvider>
	);
};

export const mdxFragment = graphql`
	fragment MdxNode on Mdx {
		body
		id
		frontmatter {
			title
			description
			order
		}
		headings {
			depth
			value
		}
		exportPropTypes
	}
`;

export const query = graphql`
	query (
		$name: String!
		$mdxPath: String
		$propsPath: String
		$hasCodeDocs: Boolean!
		$hasMdx: Boolean!
		$hasProps: Boolean!
	) {
		workspaceInfo(name: { eq: $name }) {
			title
			description
			slug
			status @include(if: $hasCodeDocs)
			name @include(if: $hasCodeDocs)
			group @include(if: $hasCodeDocs)
			repository @include(if: $hasCodeDocs)
			directoryName @include(if: $hasCodeDocs)
			definition @include(if: $hasCodeDocs)
		}
		changelog(packageName: { eq: $name }) @include(if: $hasCodeDocs) {
			mdx: childMdx {
				id
				body
				headings {
					value
					depth
				}
			}
		}
		props: mdx(fileAbsolutePath: { eq: $propsPath }) @include(if: $hasProps) {
			...MdxNode
		}
		mdx: allMdx(
			sort: { fields: frontmatter___order, order: ASC }
			filter: { fileAbsolutePath: { glob: $mdxPath, ne: $propsPath } }
		) @include(if: $hasMdx) {
			nodes {
				...MdxNode
				parent {
					... on File {
						name
					}
				}
			}
		}
	}
`;

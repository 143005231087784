/**
 * @jsxRuntime classic
 * @jsx jsx
 */
// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import { jsx } from '@emotion/react';
import ContentRenderer, {
	ContentRendererWrapper,
} from '../../__DO_NOT_ADD_TO_THIS_FOLDER__/gatsby-theme-brisk/content/content-renderer';
import TabbedSection from './tabbed-section';
interface PageContentTypeProps {
	data: any;
	pageType: 'page' | 'codePage' | 'tabbedPage';
	pageContext: { hasCodeDocs: boolean };
}

const PageContentType = ({ data, pageType, pageContext }: PageContentTypeProps) => {
	if (pageType === 'page') {
		return (
			<ContentRendererWrapper>
				<ContentRenderer id={data.mdx.nodes[0]} body={data.mdx.nodes[0]} />
			</ContentRendererWrapper>
		);
	}
	return <TabbedSection data={data} pageContext={pageContext} />;
};

export default PageContentType;

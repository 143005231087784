/**
 * @jsxRuntime classic
 * @jsx jsx
 */
// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import { jsx } from '@emotion/react';
import PageAnalytics from './page-analytics';
import PageContent from './content-type';

const PackagePage = (props: any) => {
	const pageTitle = props.data.workspaceInfo.title;
	const tabNames: string[] = props.data.mdx.nodes
		.map((node: any) => node.parent.name)
		// "code" is a magical tab which only applies for top level pages.
		.concat('code');

	return (
		<PageAnalytics tabNames={tabNames} packageName={props.pageContext.name} pageTitle={pageTitle}>
			<PageContent {...props} />
		</PageAnalytics>
	);
};

export default PackagePage;
